import React from 'react';
import { Modal } from 'src/components/Modal';
import { Image, ViewStyle, StyleProp, StyleSheet, TouchableOpacity, ScrollView, Text } from 'react-native';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';

interface PropsIface {
  onClose: () => void;
  visible: boolean;
  style?: StyleProp<ViewStyle>;
}

const closeIconSource = {
  uri: '/static/images/app/Clickable/Close.png',
};

const NotificationsModal: React.FC<PropsIface> = (props) => {
  const header = (
    <Layout.EdgeGutter
      style={[
        Constants.GridStyle.FLDR,
        Constants.GridStyle.FLAIC,
        Constants.GridStyle.MT2Unit,
        Constants.GridStyle.MB2Unit,
      ]}
    >
      <TouchableOpacity onPress={props.onClose}>
        <Image source={closeIconSource} style={[Constants.ImageStyle.StdDim, styles.closeIcon]} resizeMode='contain' />
      </TouchableOpacity>
    </Layout.EdgeGutter>
  );

  return (
    <Modal visible={props.visible} size='thin' onClose={props.onClose}>
      {header}
      <Layout.EdgeGutter>
        <Text style={[Constants.TextStyle.T24B, Constants.GridStyle.MB2Unit]}>{'Notifications'}</Text>
      </Layout.EdgeGutter>
      <ScrollView></ScrollView>
    </Modal>
  );
};

export default NotificationsModal;

const styles = StyleSheet.create({
  closeIcon: {
    tintColor: Constants.NewColor.Black,
  },
});
