import * as React from 'react';
import * as Constants from 'src/constants';
import Text from 'src/components/Text';
import Link from 'next/link';
import { ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Header from 'src/views/App/Listing/Header';

interface ContainerPropsIface {
  style?: StyleProp<ViewStyle>;
  navigationDisabled?: boolean;
}

export const ContentContainer: React.FC<ContainerPropsIface> = (props) => {
  return (
    <>
      <div className='display-max-w-860'>
        <View style={styles.bannerRoot}>
          <Text style={[Constants.TextStyle.T16M, Constants.TextStyle.CWhite]}>
            {'Want to work on a smaller screen?'}
          </Text>
          <Text style={[Constants.TextStyle.T16M, Constants.TextStyle.CWhite]}>
            {'OneShop is available on both'}{' '}
            <Link href='https://bit.ly/oneshop-ios'>
              <a target='_blank'>
                <Text style={[Constants.TextStyle.CWhite, Constants.TextStyle.T16M, Constants.TextStyle.TDLUnderline]}>
                  {'iOS'}
                </Text>
              </a>
            </Link>{' '}
            {'and'}{' '}
            <Link href='https://bit.ly/oneshop-android'>
              <a target='_blank'>
                <Text style={[Constants.TextStyle.CWhite, Constants.TextStyle.T16M, Constants.TextStyle.TDLUnderline]}>
                  {'Android'}
                </Text>
              </a>
            </Link>
            {'.'}
          </Text>
        </View>
      </div>
      <ScrollView contentContainerStyle={styles.pageContents}>
        <ScrollView horizontal contentContainerStyle={styles.root}>
          <Header navigationDisabled={props.navigationDisabled} />
          <View style={[styles.content, props.style]}>{props.children}</View>
        </ScrollView>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  bannerRoot: {
    backgroundColor: Constants.BrandColor.MidnightBorder,
    paddingVertical: Constants.Grid.dp(24),
    paddingHorizontal: Constants.Grid.dp(12),
  },
  pageContents: {
    height: '100%',
    minHeight: Constants.Grid.dp(780),
  },
  root: {
    width: '100%',
    minWidth: Constants.Grid.dp(1200),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    width: '100%',
    flex: 1,
    maxWidth: Constants.Grid.dp(1200),
  },
});

export default ContentContainer;
