import * as React from 'react';
import * as Constants from 'src/constants';
import Text from 'src/components/Text';
import { ScrollView, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

export interface FilterConfigIface<T> {
  value: T;
  leftAdornment?: (props: FilterConfigIface<T>) => JSX.Element;
  label: React.ReactNode | ((args: { selected: boolean }) => React.ReactNode);
  style?: StyleProp<ViewStyle>;
}

interface FilterSelectorButtonPropsIface<T> {
  selected: T;
  onPress: (value: T) => void;
}

function FilterSelectorButton<T>(props: FilterConfigIface<T> & FilterSelectorButtonPropsIface<T>): JSX.Element {
  const onPress = React.useCallback(() => {
    props.onPress(props.value);
  }, [props.value, props.onPress]);

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.buttonBase, props.selected === props.value && styles.buttonSelected, props.style]}
    >
      {props.leftAdornment?.(props) ?? null}
      <Text
        style={[
          Constants.TextStyle.T12M,
          props.selected === props.value ? Constants.TextStyle.CMidnight : Constants.TextStyle.CDarkGray,
        ]}
      >
        {typeof props.label === 'function' ? props.label({ selected: props.selected === props.value }) : props.label}
      </Text>
    </TouchableOpacity>
  );
}

interface PropsIface<T> {
  scrollEnabled?: boolean;
  filters: FilterConfigIface<T>[];
  defaultValue: T;
  value?: T;
  onChange: (value: T) => void;
  style?: StyleProp<ViewStyle>;
}

function FilterSelector<T>(props: PropsIface<T>): JSX.Element {
  const [selected, setSelected] = React.useState<T>(props.defaultValue);

  const onPress = React.useCallback((value: T) => {
    setSelected(value);
  }, []);

  React.useEffect(() => {
    props.onChange(selected);
  }, [selected]);

  React.useEffect(() => {
    if (props.value != null) {
      setSelected(props.value);
    }
  }, [props.value]);

  return (
    <View style={props.style}>
      <ScrollView
        horizontal
        style={styles.root}
        contentContainerStyle={props.scrollEnabled == false ? styles.noScrollContentContainer : styles.contentContainer}
        scrollEnabled={props.scrollEnabled}
        keyboardShouldPersistTaps='always'
        showsHorizontalScrollIndicator={false}
      >
        {props.filters.map((filter, index) => {
          return <FilterSelectorButton key={index} selected={selected} onPress={onPress} {...filter} />;
        })}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
  },
  contentContainer: {
    paddingLeft: Constants.Grid.dp(12),
    paddingRight: Constants.Grid.dp(24) + Constants.Grid.Unit,
  },
  noScrollContentContainer: {
    paddingHorizontal: 2 * Constants.Grid.Unit,
  },
  buttonBase: {
    height: 6 * Constants.Grid.Unit,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: Constants.Grid.Unit,
    borderRadius: 3 * Constants.Grid.Unit,
    marginRight: Constants.Grid.Unit,
    borderWidth: 3,
    borderColor: 'transparent',
  },
  buttonSelected: {
    backgroundColor: Constants.BrandColor.BackgroundGray,
    opacity: 1,
  },
});

export default FilterSelector;
