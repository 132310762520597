import * as React from 'react';
import * as Constants from 'src/constants';
import * as Observe from 'src/util/observe';
import * as State from 'src/state';
import Link from 'next/link';
import NextImage from 'next/image';
import { ScrollView, StyleSheet, View, Image, TouchableOpacity, Text } from 'react-native';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';
import { useRouter } from 'next/router';
import HorizontalSeparator from 'src/components/HorizontalSeparator';
import NotificationsModal from 'src/components/NotificationsModal';

type ListingTabs = '/u/listings' | '/u/orders' | '/u/mobile-app' | '/u/settings' | '/u/home' | '/u/bots';

const FILTERS: FilterConfigIface<ListingTabs>[] = [
  {
    value: '/u/home',
    label: 'Home',
  },
  {
    value: '/u/orders',
    label: 'Orders',
  },
  {
    value: '/u/listings',
    label: 'Crosslisting',
  },
  // {
  //   value: '/u/bots',
  //   label: 'Bot',
  // },
  {
    value: '/u/mobile-app',
    label: 'Use iOS / Android App',
  },
  {
    value: '/u/settings',
    label: 'Settings',
  },
];

interface PropsIface {
  navigationDisabled?: boolean;
}

const Header: React.FC<PropsIface> = (props) => {
  const router = useRouter();
  const [notificationsVisible, setNotificationsVisible] = React.useState<boolean>(false);
  const unarchivedCount = Observe.React.useValue(State.Observe.App.NotificationUnarchivedCountValue);

  const onChange = React.useCallback((v: ListingTabs[]) => {
    if (v[0] === '/u/listings' && router.pathname != '/u/listings') {
      router.push(`/u/listings`);
    } else if (v[0] === '/u/orders' && router.pathname != '/u/orders') {
      router.push(`/u/orders`);
    } else if (v[0] === '/u/mobile-app' && router.pathname != '/u/mobile-app') {
      router.push(`/u/mobile-app`);
    } else if (v[0] === '/u/settings' && router.pathname != '/u/settings') {
      router.push(`/u/settings`);
    } else if (v[0] === '/u/home' && router.pathname != '/u/home') {
      router.push('/u/home');
      // } else if (v[0] === '/u/bots' && router.pathname != '/u/bots') {
      //   router.push('/u/bots');
    }
  }, []);

  const host = React.useMemo(() => {
    return typeof window !== 'undefined' ? window.location.host : null;
  }, []);

  const bellImageSource = React.useMemo(() => {
    return {
      uri: '/static/images/social/navigation/Bell.png',
      width: Constants.Grid.dp(20),
      height: Constants.Grid.dp(20),
    };
  }, []);

  const helpImageSource = React.useMemo(() => {
    return {
      uri: '/static/images/social/navigation/Help.png',
      width: Constants.Grid.dp(20),
      height: Constants.Grid.dp(20),
    };
  }, []);

  const header: React.CSSProperties = React.useMemo(() => {
    return {
      position: 'sticky',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 3,
    };
  }, []);

  const defaultValue: ListingTabs[] = React.useMemo(() => {
    return router.pathname == '/u/listings'
      ? ['/u/listings']
      : router.pathname == '/u/orders'
      ? ['/u/orders']
      : router.pathname == '/u/mobile-app'
      ? ['/u/mobile-app']
      : router.pathname == '/u/settings'
      ? ['/u/settings']
      : router.pathname === '/u/home'
      ? ['/u/home']
      : // : router.pathname === '/u/bots'
        // ? ['/u/bots']
        [];
  }, [router.pathname]);

  const onCloseNotifications = React.useCallback(() => {
    setNotificationsVisible(false);
  }, []);

  const onPressNotifications = React.useCallback(() => {
    setNotificationsVisible(true);
  }, []);

  return (
    <>
      <header style={header}>
        <View style={styles.tabsRow}>
          <View style={styles.tabsContent}>
            <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
              <View style={[styles.logoImg, Constants.GridStyle.MR2Unit]}>
                <Link href='/'>
                  <a>
                    <NextImage src='/static/images/nav-logo.png?v=1' width={294} height={60} priority />
                  </a>
                </Link>
              </View>
              {!props.navigationDisabled ? (
                <InlineSelector<ListingTabs>
                  filters={FILTERS}
                  defaultValue={defaultValue}
                  onChange={onChange}
                  style={Constants.GridStyle.MTUnit}
                />
              ) : null}
            </View>
            <View style={styles.rightColumn}>
              <TouchableOpacity onPress={onPressNotifications} style={styles.buttonRoot}>
                <Image
                  source={bellImageSource}
                  width={Constants.Grid.dp(20)}
                  height={Constants.Grid.dp(20)}
                  style={styles.icon}
                  resizeMode='contain'
                />
                {unarchivedCount != null && unarchivedCount > 0 ? (
                  <View style={styles.notificationIndicator}>
                    <Text style={[Constants.TextStyle.T8M, Constants.TextStyle.CWhite]}>{`${Math.min(
                      unarchivedCount,
                      9
                    )}${unarchivedCount > 9 ? '+' : ''}`}</Text>
                  </View>
                ) : null}
              </TouchableOpacity>
              <Link href='/contact'>
                <a>
                  <Image
                    source={helpImageSource}
                    width={Constants.Grid.dp(20)}
                    height={Constants.Grid.dp(20)}
                    style={styles.icon}
                    resizeMode='contain'
                  />
                </a>
              </Link>
            </View>
          </View>
        </View>
      </header>
      <NotificationsModal visible={notificationsVisible} onClose={onCloseNotifications} />
    </>
  );
};

const styles = StyleSheet.create({
  headerRow: {
    width: '100%',
    height: Constants.Grid.dp(48),
    minWidth: Constants.Grid.dp(1200),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Constants.NewColor.BackgroundGray,
    borderBottomColor: Constants.NewColor.OnWhiteBorderGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  headerContent: {
    width: '100%',
    maxWidth: Constants.Grid.dp(1200),
    paddingHorizontal: Constants.Grid.dp(12),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoImg: {
    width: Constants.Grid.dp(117),
    height: Constants.Grid.dp(24),
  },
  rightColumn: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tabsRow: {
    minWidth: Constants.Grid.dp(1200),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Constants.NewColor.White,
  },
  tabsContent: {
    width: '100%',
    maxWidth: Constants.Grid.dp(1200),
    paddingHorizontal: Constants.Grid.dp(12),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: Constants.Grid.dp(6),
  },
  icon: {
    width: Constants.Grid.dpFromPx(20),
    height: Constants.Grid.dpFromPx(20),
    tintColor: Constants.NewColor.Black,
    marginRight: Constants.Grid.dp(24),
  },
  buttonRoot: {
    justifyContent: 'center',
    alignItems: 'center',
    height: Constants.Grid.dp(48),
    marginRight: Constants.Grid.dp(6),
  },
  notificationIndicator: {
    position: 'absolute',
    top: Constants.Grid.dp(12),
    right: Constants.Grid.dp(24),
    backgroundColor: Constants.NewColor.AccentRed,
    borderRadius: Constants.Grid.dp(3),
    height: Constants.Grid.dp(12),
    minWidth: Constants.Grid.dp(12),
    paddingHorizontal: Constants.Grid.dp(3),
  },
});

export default React.memo(Header);
